import { Routes } from "@angular/router";
import { AppComponent } from "../app/app.component"


export const rootRouterConfig: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./nursing-display/nursing-display.module').then(m => m.AppNursingDisplayModule),
        data: { title: "Nursing Station Display", breadcrumb: "Nursing Station Display" }
      },
    ]
  },

];