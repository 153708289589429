import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
  ) { }


  get(tenant, path: string, params: HttpParams = new HttpParams()): Observable<any> {
    let urlTenant = environment.apiURL.replace('{tenant}', tenant);
    return this.http.get(`${urlTenant}${path}`, { params });
  }

  put(tenant, path: string, body: Object = {}): Observable<any> {
    let urlTenant = environment.apiURL.replace('{tenant}', tenant);
    return this.http.put(
      `${urlTenant}${path}`,
      body
    );
  }

  post(tenant, path: string, body: Object = {}): Observable<any> {
    let urlTenant = environment.apiURL.replace('{tenant}', tenant);
    return this.http.post(
      `${urlTenant}${path}`,
      body
    );
  }

  delete(tenant, path): Observable<any> {
    let urlTenant = environment.apiURL.replace('{tenant}', tenant);
    return this.http.delete(
      `${urlTenant}${path}`
    );
  }

  ///// device api call /////
  deviceget(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.deviceApiURl}${path}`, { params });
  }

  deviceput(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.deviceApiURl}${path}`,
      body
    );
  }

  devicepost(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.deviceApiURl}${path}`,
      body
    );
  }

  devicedelete(path: string, body: Object = {}): Observable<any> {
    return this.http.delete(
      `${environment.deviceApiURl}${path}`, body
    );
  }

}
