import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedMaterialModule } from './shared-material.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { ToastService } from './services/toast.service';
import { IMqttMessage, MqttModule, IMqttServiceOptions } from "ngx-mqtt";
import { environment } from 'src/environments/environment';
import { rootRouterConfig } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
const MQTT_SERVICE_OPTIONS: any = environment.mqttConfig;

@NgModule({
  declarations: [
    AppComponent,
    AppLoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedMaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    RouterModule.forRoot(rootRouterConfig)
  ],
  providers: [ApiService, ToastService],
  bootstrap: [AppComponent],
  exports: [
    SharedMaterialModule
  ]
})
export class AppModule { }
