// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: "https://{tenant}.petalred.in/api/V1/HP/",
  deviceApiURl: "https://petaltrack.tech:8010/",
  mqttConfig: {
    hostname: "device.petalred.com",
    port: 8084,
    path: "/mqtt",
    protocol: "wss",
    username : "PetalHosWssUser"  ,
    password : "PetalHoswSS@20#19!"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
